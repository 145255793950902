import React from "react"
import "./service.css"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getWebImage = graphql`
  {
    fixed: file(relativePath: { eq: "i_web.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed2: file(relativePath: { eq: "i_mobile.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed3: file(relativePath: { eq: "i_cloud.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed4: file(relativePath: { eq: "i_devops.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed5: file(relativePath: { eq: "i_ai.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed6: file(relativePath: { eq: "i_blockchain.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed7: file(relativePath: { eq: "i_iot.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed8: file(relativePath: { eq: "i_bigdata.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed9: file(relativePath: { eq: "i_startup.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }`
const Offer = () => {
  const data = useStaticQuery(getWebImage);
  return (
    <div className="features-boxed">
      <div className="container">
        <div data-aos-duration="1000" data-aos="fade-down" className="intro">
          <h2 className="text-center">
            We provide solutions to grow your business
          </h2>
        </div>
        <div className="row justify-content-center features">
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services"> 
            <Img className="icon"  fixed={data.fixed.childImageSharp.fixed} />
              <h3 className="name">Web Development</h3>
                            </Link>
               
            </div>
            
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#mobile-service-scroll">
            <Img className="icon" fixed={data.fixed2.childImageSharp.fixed} />
              <h3 className="name">Mobile Development</h3>
              </Link>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#cloud-service-scroll">
            <Img className="icon" fixed={data.fixed3.childImageSharp.fixed} />
              <h3 className="name">Cloud Solutions</h3></Link>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#devops-service-scroll">
            <Img className="icon"  fixed={data.fixed4.childImageSharp.fixed} />
              <h3 className="name">DevOps Solutions </h3></Link>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#ai-service-scroll">
            <Img className="icon"  fixed={data.fixed5.childImageSharp.fixed} />
              <h3 className="name">AI Solutions</h3></Link>
            </div>
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#blockchain-service-scroll">
            <Img className="icon"  fixed={data.fixed6.childImageSharp.fixed} />
              <h3 className="name">Blockchain Solutions</h3>
             
              </Link>
            </div>
          </div>
         
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#iot-service-scroll">
            <Img className="icon"  fixed={data.fixed7.childImageSharp.fixed} />
              <h3 className="name">IoT Solutions</h3>
             
              </Link>
            </div>
          </div>
         
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#bigdata-service-scroll">
            <Img className="icon"  fixed={data.fixed8.childImageSharp.fixed} />
              <h3 className="name">Big Data Solutions</h3>             
              </Link>
            </div>
          </div>
         
          <div data-aos="zoom-in" data-aos-duration="1000" className="col-sm-6 col-md-5 col-lg-4 item">
            <div className="box">
            <Link className="nav-link" to="/services/#startup-service-scroll">
            <Img className="icon"  fixed={data.fixed9.childImageSharp.fixed} />
              <h3 className="name">Startup Solutions</h3>             
              </Link>
            </div>
          </div>
         
          <div className="mx-auto">
          <Link className="nav-link" to="/services"> <button className="btnh btn-3">Explore Services</button></Link>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offer
