import React from "react"
import "./domain.css"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getWebImage = graphql`
  {
    fixed: file(relativePath: { eq: "i_ecom.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed2: file(relativePath: { eq: "i_finance.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed3: file(relativePath: { eq: "i_health.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed4: file(relativePath: { eq: "i_entertainement.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed5: file(relativePath: { eq: "i_travel.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed6: file(relativePath: { eq: "i_food.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed7: file(relativePath: { eq: "i_education.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed8: file(relativePath: { eq: "i_event.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed9: file(relativePath: { eq: "i_transportation.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed10: file(relativePath: { eq: "i_aviation.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed11: file(relativePath: { eq: "i_water.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    fixed12: file(relativePath: { eq: "i_gov.png" }) {
      childImageSharp {
        fixed(webpQuality: 100, quality:100, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }`


const Domain = () => {
  const data = useStaticQuery(getWebImage);
  return (
    <div>
      <div className="section-block-grey">
        <div className="container">
          <div data-aos-duration="1000" data-aos="fade-down" className="section-heading center-holder text-center">
            <h2 className="domain-text">Business Domains We Cater To</h2>
                      
          </div>
          <div className="row mt-60">
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>e-commerce</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2 serv-section-2-act">
                <div className="serv-section-2-icon serv-section-2-icon-act">
                  
                  <Img className="icon"  fixed={data.fixed2.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Banking & Finance</h4>                  
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed3.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Healthcare</h4>                 
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>
          </div>

          <div className="row mt-60">
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed4.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Entertainement</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2 serv-section-2-act">
                <div className="serv-section-2-icon serv-section-2-icon-act">
                  
                  <Img className="icon"  fixed={data.fixed5.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Travel</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed6.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Food Delivery</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>
          </div>


          <div className="row mt-60">
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed7.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Education</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2 serv-section-2-act">
                <div className="serv-section-2-icon serv-section-2-icon-act">
                  
                  <Img className="icon"  fixed={data.fixed8.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Events</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>

            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed9.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Transportation</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>
          </div>

          

          <div className="row mt-60">
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2">
                <div className="serv-section-2-icon">
                  
                  <Img className="icon"  fixed={data.fixed10.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Aviation</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
              <div className="serv-section-2 serv-section-2-act">
                <div className="serv-section-2-icon serv-section-2-icon-act">
                <Img className="icon"  fixed={data.fixed11.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Water Sector</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            </div>
            <div  data-aos="fade-up"
     data-aos-duration="1000" className="col-md-4 col-sm-12 col-12">
            <div className="serv-section-2 serv-section-2-act">
                <div className="serv-section-2-icon serv-section-2-icon-act">
                <Img className="icon"  fixed={data.fixed12.childImageSharp.fixed} />
                </div>
                <div className="serv-section-desc">
                  <h4>Government</h4>
                </div>
                <div className="section-heading-line-left"></div>
              </div>
            {/* <article className="dcard">
            
  <div className="infos">
    <h2 className="title">new york city<span className="flag"></span></h2>
    <h3 className="date">november 2 - 4</h3>
    <h3 className="seats">seats remaining: 2</h3>
    <p className="txt">
      Join us for our Live Infinity Session in
      beautiful New York City. This is a 3 day
      intensive workshop where you'll learn
      how to become a better version of...
    </p>
   
  </div>
</article> */}
            </div>
          
          </div>
      
      
      
      
      
      
      
      
      
      
      
        </div>
      </div>
    </div>
  )
}

export default Domain
