import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Service from "../components/main-offer/service"
import Domain from "../components/domain/domain"
import Contact from "../components/contact/contact"
import Hero3 from "../components/hero/hero3"

const IndexPage = () => (
  <Layout>
    <SEO title="Techvizon | Software Development &amp; IT Solutions Company" pathname="/" description="Transforming Your Vision Into Reality Through Technology" />
    <Hero3 />
    <Service />
    <Domain />
    <Contact />
  </Layout>
)

export default IndexPage
