import React from "react"
import "./hero3.css"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"


const Hero3 = () => {
const customeEvent = () => {
  
  if (typeof window !== "undefined") {
  window.gtag('event', 'click', {
    'event_category': 'calltoa',
    'event_label': 'Hero Call To Action'
  });
  
  }
}
 
  return (
    <div className="hero">
      <div className="container">
        <div className="row">
          <div  className="col-xs-12 wrap">
            <h4 data-aos="fade-up" className="wordCarousel">
           We Transform Your Vision Into Reality Through
              <div className="flipdiv">
                <ul className="flip2">
                  <li className="ftext1">INNOVATIVE</li>
                  <li className="ftext2">CUTTING EDGE</li>
                </ul>
              </div>
              <div className="ptech">
                <span>Technology</span>
              </div>
            </h4>
            <div className="col actionbtn mr-auto py-2">
            <Link onClick={()=>customeEvent()} className="btnch" to='/contact'>Let's Discuss</Link>
            
          </div>
        
         </div>
        </div>
      </div>
     
    </div>
  )
}

export default Hero3
