import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import "./contact.css"
const getCallingImage = graphql`
  {
    fluid: file(relativePath: { eq: "idea2.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Contact = () => {
  const data = useStaticQuery(getCallingImage);
  const customeEvent = () => {
  
    if (typeof window !== "undefined") {
    window.gtag('event', 'click', {
      'event_category': 'Career',
      'event_label': 'Mail'
    });
    
    }
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" className="col-md-6 quote py-5">
            <div className="container py-lg-4">
              <div className="quote-left">
                <div className="left">
                  <h3 className="title-big mb-3">
                    Lets discuss your Idea, We're ready to work for you
                  </h3>
                  <p>Don't worry your idea is safe with us.</p> <br />
                  <p>We first sign an NDA & then discuss further !</p>
                </div>
              </div>
            </div>
            <div className="cnbtn my-auto mx-auto">
              <Link onClick={()=>customeEvent()} to='/contact' className="btncontact btncontact-3">Contact Us</Link>
            </div>
          </div>
          <div 
     
      className="col-md-6 my-auto mx-auto py-4">
            <Img fluid={data.fluid.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
